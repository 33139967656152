<template>
  <section id="not-found" class="section-content text-center">
    <div class="container">

      <div class="illustration">
        <img src="/images/img-notfound.svg" loading="lazy">
      </div>

      <div class="content">
        <h2>Página não encontrada</h2>
        <p>Não encontramos o que você procura. Acesse a nossa página inicial.</p>

        <router-link class="nav-link" :to="{ name: 'home'}">
          <ButtonDefault
            class="button"
            rotulo="Página Inicial"
            btnStyle="primary"
            btnSize="small"
          />
        </router-link>
      </div>      
    </div>
  </section>
</template>

<script>
import LayoutFull from "@/layouts/LayoutFull.vue";
import ButtonDefault from "@/components/shared/buttons/button-default.vue";

export default {
  components: {    
    ButtonDefault
  },

  data: function () {
    return {
      responseQuotationForm: "",
    };
  },

  created() {
    this.$emit(`update:layout`, LayoutFull);
    this.$emit(`update:title`, "Faça uma Cotação");
    this.$emit(
      `update:introduction`,
      "Fale com nossos especialistas e solicite um orçamento sem compromisso para aplicar películas e adesivos"
    );
    this.$emit(`update:bgHeader`, "white");
  },
  methods: {
    responseQuotation: function (response) {
      this.responseQuotationForm = response;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";

.illustration {
  margin-bottom: 1rem;

  @media (min-width: $screenLarge) {
    margin-bottom: 2rem;
  }

  img {
    max-width: 100%;

    @media (min-width: $screenLarge) {
      width: 485px;
      height: 268px;
    }
  }
}

</style>