<template>
  <div class="LayoutFull">
    <header :class="getBgHeader">
      <Navbar 
        :layoutFull="layoutFull"
      />
      <div class="content-hero"></div>
      <!-- content-hero end -->
    </header>
    <main class="LayoutFull__main">
      <transition name="fade" mode="out-in">
      <slot />
      </transition>
    </main>

    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/shared/menu/navbar.vue";
import Footer from "@/components/shared/footer/footer.vue";

import { goToElement } from "@/mixin/go-to-element-mixin.js";

export default {
  mixins: [goToElement],

  components: {
    Navbar,
    Footer,
  },

  

  name: `LayoutFull`,

  props: {
    title: String,
    introduction: String,
    bgHeader: String,
  },

  data() {
    return {
      user: null,      
      layoutFull: true,         
    };
  },
  created() {

    this.$myGlobalCheckCreated.layoutInternal = 'created';
    this.$myGlobalCheckCreated.layoutDefault = 'not-created';

    //console.log(this.$myGlobalCheckCreated.layoutInternal, 'this.$myGlobalCheckCreated.layoutInternal - layoutInternal')

    // Simulate fetching user data.
    setTimeout(() => {
      this.user = { name: `John Doe` };
    }, 2000),
      this.$nextTick(function () {
        // lazy
        //console.warn(this.$scopedSlots.default()[0].context.$refs)
      });
  },
  computed: {
    getBgHeader : function () {
      return {
        'bg-color-blue-500' : this.bgHeader === 'blue',
        'bg-color-green-500' : this.bgHeader === 'green',
        'bg-color-red-500' : this.bgHeader === 'red',
        'bg-color-black' : this.bgHeader === 'black',
      }
    }
  }

};
</script>

<style lang="scss">
@import "@/styles/_variables.scss";

#app {
    display: flex;
    flex-direction: column;
    height: inherit;

    main {
        flex: 1 0 auto;
    }

    >*:last-child {
        flex-shrink: 0;
    }
}

.LayoutFull {
  //main com a área disponível entre o header e footer
  display: flex;
  flex-direction: column;
  height: inherit;

  main {
      flex: 1 0 auto;
  }

  >*:last-child {
      flex-shrink: 0;
  }

  //main com a área disponível entre o header e footer

  .content-hero {
    height: 61px;    

    @media (min-width: $screenLarge) {
      height: 85px;    
    }
  }


}
</style>

